<template>
  <div class="home" ref="Home">
    <swiper
      :slides-per-view="1"
      :pagination="bannerOptions.pagination"
      :autoplay="bannerOptions.autoplay"
      :loop="bannerOptions.loop"
      ref="banner"
      class="banner"
    >
      <swiper-slide>
        <img src="@/assets/20221107154526ade7f9.jpg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/202211071558140c46d0.jpg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/202211071558149fdab0.jpg" alt="">
      </swiper-slide>
      <div class="pagination"></div>
    </swiper>
    <div class="project" ref="Project">
      <div class="content">
        <div class="title">
          <h1>主营项目</h1>
          <h2>Main project</h2>
        </div>
        <div class="info">
          <div class="list">
            <b>新能源&智能化项目运营</b>
            <p style="color: #999999">——综合智慧平台</p>
            <ul>
              <li>
                <b>（1）能源端建设方案</b>
                <p style="text-indent: 36px;">分布式光伏&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;分散式风电</p>
                <p style="text-indent: 36px;">天然气分布式能源</p>
                <p style="text-indent: 36px;">集中供冷供热系统、垃圾发电</p>
              </li>
              <li>
                <b>（2）储能端建设方案</b>
                <p style="text-indent: 36px;">电储能&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;抽水蓄能</p>
                <p style="text-indent: 36px;">冰蓄能&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;V2G充电桩</p>
              </li>
              <li>
                <b>（3）综合智慧能源控制</b>
                <p style="text-indent: 36px;">综合能源管理系统（IEMS）</p>
              </li>
              <li>
                <b>（4）智能化运营管理</b>
                <p style="text-indent: 36px;">能源管理&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;安防联动</p>
                <p style="text-indent: 36px;">数据融合&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;智能分析</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>***电网</b>
            <ul>
              <li>
                <p>配电</p>
                <p>充电</p>
                <p>泛在电力物联网</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>能源与基础设施</b>
            <ul>
              <li>
                <p>电力</p>
                <p>石化</p>
                <p>光伏及风电</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>工业</b>
            <ul>
              <li>
                <p>钢铁</p>
                <p>煤炭</p>
                <p>化工</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>市政</b>
            <ul>
              <li>
                <p>供热</p>
                <p>供水</p>
                <p>道路</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>建筑</b>
            <ul>
              <li>
                <p>工业园区</p>
                <p>医院及酒店</p>
                <p>办公及商超</p>
                <p>商业中心</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>轨道交通</b>
            <ul>
              <li>
                <p>城市地铁</p>
                <p>城际铁路</p>
                <p>高速铁路</p>
                <p>机场</p>
              </li>
            </ul>
          </div>
          <div class="list">
            <b>智能管理</b>
            <ul>
              <li>
                <p>市政管理</p>
                <p>园区管理</p>
                <p>社会安防</p>
                <p>节能提效</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="services" ref="Services">
      <div class="content">
        <div class="title">
          <h1>主要服务</h1>
          <h2>Main services</h2>
        </div>
        <div class="info">
          <div class="list">
            <img src="@/assets/202211141148309d2241.png" alt="">
            <b>智能化工程建设项目</b>
            <p>在绿色智慧城市建设的智能化项目工程模块中，公司围绕智慧、安全、***、人文、健康、绿色六大体系，建设可持续发展的新型智慧城市，为全社会提供智慧服务。未来公司将成为中国领先的智慧城市智能设备及大数据运营供应商和服务商。</p>
          </div>
          <div class="list">
            <img src="@/assets/2022111411483024da36.png" alt="">
            <b>智能物联系列产品</b>
            <p>公司在系统集成，通信技术研发，智能识别、数据融合、移动计算等多种核心应用技术的研发融合，目前已自研包含：网络高清摄像机/储存单元/红外热成像仪/通信控制器等多种支持智慧城市项目工程建设所需设备与集成系统技术。</p>
          </div>
          <div class="list">
            <img src="@/assets/20221114114830cfba92.png" alt="">
            <b>新能源工程建设项目</b>
            <p>新能源工程项目建设是围绕着以整个城市绿色智能化开发为主题的城市用电站、区级中小规模地面电站；企业、政府、医院、学校等企事业单位屋顶分布式电站的工程开发与建设。<br/>可应用于工业储能，充电桩等综合能源利用；同时在农光互补、渔光互补、林光互补等设施农业地面集中式电站等亦有相当广泛的应用面。</p>
          </div>
          <div class="list">
            <img src="@/assets/20221114114830417bf6.png" alt="">
            <b>绿色智慧城市项目运营服务</b>
            <p>公司依托高端制造优势，深化技术革新，产品升级，适应市场变化与客户需求，打造数据价值，构建能源、智能化与城市数据系统，为建设绿色智慧城市提供新能源与智能化的项目运营解决方案与服务。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="about" ref="About">
      <div class="content">
        <div class="title">
          <h1>关于我们</h1>
          <h2>About us</h2>
        </div>
        <div class="info">
          <p>广东有福哩项目管理有限公司成立于2009年，于2016年2月在全国中小企业股份转让中心（新三板）成功挂牌上市（股票代码：835853），并于2021年5月份成功晋升创新层，市值约8.15亿元人民币。是一家以智能机器人、智能设备研发、生产、销售于一体的多元化物联科技公司。</p>
          <p>公司创立以来，已成功在智能机器人、设备、物联云平台打造研、产、销一体产业链，涉及智能电子产品硬件、软件技术、智能化项目工程建设等多个板块。我们在各领域的专业水平被各行业权威机构认可，与国内外知名院校/科研单位共同合作，目前拥有千名雇员，业务遍及30多个地区。星原文化秉承着创新、融合、务实的企业理念，提供一站式人工智能物联网的设备与解决方案。涵盖了硬件开发、云控制、智慧商业平台等方面，为广大客户提供物联产品与万物物联赋能服务！</p>
          <p>在***“双碳”和“数字中国”的双驱动下，公司致力于成为建设以新型能源结构为基底，以智能化技术为保障的绿色智慧城市践行者、服务者与建设者。公司拥有产业供应链、城市智能技术服务与绿色智慧城市项目工程建设的核心业务块，在行业中具备成本优势、技术优势以及项目核心经验优势，可以根据客户实际需求进行高质有效的服务，从而加速客户与推动***智能转型和数字化发展，***促进绿色智慧科技赋能各行各业。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue'

import SwiperCore, { Scrollbar, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/swiper.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Scrollbar, Pagination, Autoplay])

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    routerItems: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    routerName: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup (props, { emit }) {
    const instance = getCurrentInstance()
    const state = reactive({
      bannerOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.banner .pagination',
          bulletClass: 'bullet',
          bulletActiveClass: 'bullet-active',
          clickable: true
        }
      }
    })

    const scrollToTop = () => {
      let routerName = null
      const top = window.pageYOffset
      props.routerItems.forEach(item => {
        if (top >= instance.refs[item.value].offsetTop - 80) {
          routerName = item.value
        }
      })
      emit('getNavChange', {
        value: routerName,
        type: 'scroll'
      })
    }

    onMounted(() => {
      window.addEventListener('scroll', scrollToTop, true)
    })

    return {
      ...toRefs(state)
    }
  },
  watch: {
    routerName (val) {
      const sTop = this.$refs[val].offsetTop
      window.scrollTo({
        top: sTop - 80,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
}
::v-deep .banner .pagination .bullet {
  width: 100px;
  height: 5px;
  background-color: #ffffff;
  margin: 0 5px;
  cursor: pointer;
}
::v-deep .banner .pagination .bullet.bullet-active {
  background-color: #ea0e0e;
}
.project {
  width: 100%;
  background-image: url('~@/assets/201810271605249cef44.jpg');
  background-position: center center;
  background-size: cover;
  .content {
    padding: 50px 0;
    .title {
      text-align: left;
      h1 {
        line-height: 30px;
        font-size: 24px;
      }
      h2 {
        line-height: 20px;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .info {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .list {
        text-align: left;
        line-height: 26px;
        font-size: 16px;
        color: #333333;
        margin-right: 40px;
        ul {
          margin-top: 10px;
          line-height: 24px;
          font-size: 14px;
        }
      }
    }
  }
}
.services {
  width: 100%;
  background-image: url('~@/assets/20221025185604799fc0.jpg');
  background-position: center center;
  background-size: cover;
  .content {
    padding: 50px 0;
    .title {
      text-align: left;
      color: #ffffff;
      h1 {
        line-height: 30px;
        font-size: 24px;
      }
      h2 {
        line-height: 20px;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .info {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .list {
        width: 240px;
        text-align: left;
        color: #ffffff;
        img {
          display: block;
          width: 104px;
          height: auto;
          margin-bottom: 20px;
        }
        b {
          display: block;
          line-height: 20px;
          font-size: 16px;
          margin-bottom: 10px;
        }
        p {
          line-height: 22px;
          font-size: 14px;
        }
      }
    }
  }
}
.about {
  width: 100%;
  background-image: url('~@/assets/202211071616274ba043.jpg');
  background-position: center center;
  background-size: cover;
  .content {
    padding: 50px 0;
    .title {
      text-align: left;
      color: #333333;
      h1 {
        line-height: 30px;
        font-size: 24px;
      }
      h2 {
        line-height: 20px;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .info {
      width: 100%;
      margin-top: 30px;
      box-sizing: border-box;
      padding: 20px 20px;
      background-color: #ffffff;
      p {
        text-align: left;
        line-height: 24px;
        font-size: 14px;
        color: #666666;
        text-indent: 28px;
      }
    }
  }
}
</style>
